import request from '@/utils/request'

// 生成汇算清缴报表
export function yearSave(data) {
  return request({
    url: 'api/v2/year/yearSave',
    method: 'post',
    data
  })
}

// 列表
export function yearInfo(data) {
  return request({
    url: 'api/v2/year/yearInfo',
    method: 'post',
    data
  })
}

// 保存
export function yearEdit(data) {
  return request({
    url: 'api/v2/year/yearEdit',
    method: 'post',
    data
  })
}

// A000000表格股东列表
export function yearGdInfo(data) {
  return request({
    url: '/api/v2/year/yearGdInfo',
    method: 'post',
    data
  })
}
// A000000表格股东保存
export function yearGdEdit(data) {
  return request({
    url: '/api/v2/year/yearGdEdit',
    method: 'post',
    data
  })
}


// 汇算清缴查看数据
export function yearInfoCj(data) {
  return request({
    url: '/api/v2/year/yearInfoCj',
    method: 'post',
    data
  })
}
// 汇算清缴d导出
export function exYearInfo(data) {
  return request({
    url: '/api/v2/export/exYearInfo',
    method: 'post',
    data
  })
}
